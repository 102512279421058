.main {
    width: 100%;
    height: 100%;
    display: flex;
    background:
        linear-gradient(rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)),
        url("../../assets/bck_login.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.container {

    background: rgb(6 8 28 / 58%);
    backdrop-filter: blur(8px);
    width: 450px;
    height: 475px;
    border-radius: 7%;
    margin-right: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.logo {
    width: 370px;
    position: absolute;
    top: -150px;
    z-index: -1;
    margin-left: -40px;
}

.input_container {
    width: 350px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    height: 32px;
    background-color: #131c29;
    color: #4b515d;
    margin: 5px 0;
    border-radius: 10px;
    border: 1px solid #313131;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, .07);
    font-family: inherit;
    font-size: 1em;
    line-height: 1.45;
    outline: none;
    padding: 0.6em 1.45em 0.7em;
    transition: .18s ease-out;
    width: 80%;
}

.input:focus {
    color: #8f8f8f;
    border: 1px px solid #80bfdf;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, .01), 0 0 8px rgba(0, 0, 0, .2);
}

.input:hover {
    box-shadow: inset 1px 0 5px #80bfdf;
}





/* button */




.button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    color: #72c2ed;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 16px;
    overflow: hidden;
    transition: .2s;
    margin-top: 20px;
}

.button:hover {
    color: #255784;
    background: #72c2ed;
    box-shadow: 0 0 1px #72c2ed, 0 0 14px #72c2ed, 0 0 22px #72c2ed;
    transition-delay: .2s;
}

.button span {
    position: absolute;
    display: block;
}

.button span:first-child {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #72c2ed);
}

.button:hover span:first-child {
    left: 100%;
    transition: 1s;
}

.button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #72c2ed);
}

.button:hover span:nth-child(2) {
    top: 100%;
    transition: 1s;
    transition-delay: 25ms;
}

.button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #72c2ed);
}

.button:hover span:nth-child(3) {
    right: 100%;
    transition: 1s;
    transition-delay: .05s;
}

.button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(1turn, transparent, #72c2ed);
}

.button:hover span:nth-child(4) {
    bottom: 100%;
    transition: 1s;
    transition-delay: 75ms;
}


@media only screen and (max-width: 1450px) {
    .container {
        margin-right: 200px;
    }
}


/* Media queries for responsiveness */

@media only screen and (max-width: 1200px) {
    .container {
        margin-right: 100px;
        width: 400px;
        height: 450px;
    }

    .logo {
        width: 345px;
        top: -120px;
        margin-left: -40px;
    }

    .input_container {
        width: 300px;
        height: 220px;
    }
}

@media only screen and (max-width: 768px) {
    .main {
        justify-content: center;
        background: none;
        background-size: cover;
        background-color: #131c29;
    }

    .container {
        margin-right: 0;
        width: 350px;
        height: 400px;
        border-radius: 10px;
        background-color: #24344d;
    }

    .logo {
        width: 325px;
        top: -150px;
        margin-left: -30px;
    }

    .input_container {
        width: 280px;
        height: 240px;
    }
}

@media only screen and (max-width: 480px) {
    .container {
        width: 90%;
        height: 350px;
        border-radius: 10px;
    }

    .logo {
        width: 350px;
        top: -185px;
    }

    .input_container {
        width: 90%;
        height: 215px;
    }

    .input {
        font-size: 0.9em;
        padding: 0.5em 1em 0.6em;
        width: 87%;
    }

    .button {
        font-size: 14px;
        padding: 12px 25px;
    }
}

@media only screen and (max-width: 320px) {
    .container {
        width: 250px;
        height: 300px;
        border-radius: 10px;
    }

    .logo {
        width: 180px;
        top: -70px;
    }

    .input_container {
        width: 220px;
        height: 190px;
    }

    .input {
        font-size: 0.8em;
        padding: 0.4em 0.8em 0.5em;
    }

    .button {
        font-size: 12px;
        padding: 10px 20px;
    }
}