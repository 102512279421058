.firstRaw {
    display: flex;
    height: 55px;
    width: 100%;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
}



.active {
    width: 60px;
    height: 13px;
    border-radius: 25px;
    background-color: green;
    border: 1px solid white;
}

.deactive {
    width: 60px;
    height: 13px;
    border-radius: 25px;
    background-color: rgb(128, 4, 0);
    border: 1px solid white;
}
.singleTitleContainer {
    width: 130px;
    min-width: 110px;
}

.singleTitleContainer:nth-child(1) {
    margin-left: 30px;
}

.singleTitleContainer:nth-child(2) {
    margin-left: 40px;
}

.singleTitleContainer:nth-child(3) {
    margin-left: 70px;
}

.singleTitleContainer:nth-child(4) {
    margin-left: 70px;
}

.singleTitleContainer:nth-child(5) {
    margin-left: 70px;
    margin-right: 30px;
}

.dataListTitlesContainer {
    width: 100%;
    min-width: fit-content;
    height: 65px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    font-weight: bold;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
}

.tableRow {
    width: 100%;
    min-width: 800px;
    height: 45px;
    background-color: #24344d;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    cursor: pointer;
}

.tableRow:hover {
    background-color: #1e82a1;
}



.tableRowContainer {
    height: fit-content;
    min-width: fit-content;
    width: 100%;
    transition: height .5s;
}

.tableRowContainerOpened {
    transition: height .5s;
    height: 400px;
    min-width: fit-content;
    width: 100%;
}

.userInfoContainer {
    transition: height .5s;
    height: 365px;
    width: calc(100% - 20px);
    margin-left: 11px;
    background-color: #131c29;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    color: #c1c6ca;
    margin-top: 10px;
}
.userInfoContainerClosed{
    height: 0px;
}
.userInfoContainerFlex{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    flex-direction: column;
}

.singleInfoContainer{
    width: fit-content;
    height: 60px;
    margin: 20px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #09c5ff;
    border-radius: 18px;
}

.singleInfoTitle{
    font-size: 19px;
    color: #09c5ff;
    font-weight: bold;
}

.singleInfoContent{
    font-size: 17px;
    color: #c1c6ca;
    margin-left: 20px;
    margin-top: 2px;
}
.icon{
    font-size: 23px;
    margin-top: 2px;

}

.buttonUserContainer {
    width: 140px;
    height: 45px;
    background-color: #098563;
    display: flex;
    align-items: center;
    color: #c1c6ca;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 15px;
    text-decoration: none;
}
.buttonUserContainer:hover {
    background-color: #044332;
}
.buttonUserContainerDelete {
    width: 140px;
    height: 45px;
    background-color: #8a0505;
    display: flex;
    align-items: center;
    color: #c1c6ca;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 15px;
}

.buttonUserContainerDelete:hover {
    background-color: #570404;
}
.buttonIcon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}


.iconYes{
    font-size: 21px;
    margin-left: 15px;
    color: #01ff01;
}

.iconX{
    font-size: 23px;
    margin-left: 16px;
    color: red;
}

@media only screen and (max-width: 700px){
    .singleInfoTitle {
        font-size: 15px;
    }
    .singleInfoContent {
        font-size: 14px;
        margin-left: 15px;
        margin-top: 0px;
    }
    .singleInfoContainer {
        height: 55px;
        margin: 14px;
        padding: 0 12px;
        border-radius: 18px;
    }
    .buttonUserContainer {
        width: 125px;
        height: 36px;
        border-radius: 5px;
        margin-left: 12px;
        margin-top: 9px;
        font-size: 13px;
    }
    .buttonUserContainerDelete {
        width: 125px;
        height: 36px;
        border-radius: 5px;
        margin-left: 12px;
        margin-top: 9px;
        font-size: 13px;
    }
    .tableRowContainerOpened {
        height: 340px;
    }
    .userInfoContainer {
        height: 280px;
        width: calc(100% - 20px);
        margin-left: 11px;
        border-radius: 25px;
        margin-top: 10px;
    }
}