.firstRaw {
    display: flex;
    height: 55px;
    width: 100%;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
}

.inputContainer {
    width: 305px;
    height: 55px;
    align-self: flex-end;
    margin-top: 15px;
}

.button {
    width: 90px;
    height: 45px;
    background-color: #1e82a1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c6ca;
    cursor: pointer;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.button:hover {
    background-color: #13586d;
}

.input {
    height: 18px;
    background-color: #131c29;
    color: #4b515d;
    margin: 15px 0;
    border-radius: 10px;
    border: 1px solid #313131;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, .07);
    font-family: inherit;
    font-size: 1em;
    line-height: 1.45;
    outline: none;
    padding: 0.6em 1.45em 0.7em;
    transition: .18s ease-out;
    width: 240px;
}

.active {
    width: 60px;
    height: 13px;
    border-radius: 25px;
    background-color: green;
    border: 1px solid white;
}

.deactive {
    width: 60px;
    height: 13px;
    border-radius: 25px;
    background-color: rgb(128, 4, 0);
    border: 1px solid white;
}

.input:focus {
    color: #8f8f8f;
    border: 1px px solid #80bfdf;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, .01), 0 0 8px rgba(0, 0, 0, .2);
}

.input:hover {
    box-shadow: inset 1px 0 5px #80bfdf;
}

.singleTitleContainer {
    width: 130px;
    min-width: 110px;
}

.singleTitleContainer:nth-child(1) {
    margin-left: 30px;
}

.singleTitleContainer:nth-child(2) {
    margin-left: 40px;
}

.singleTitleContainer:nth-child(3) {
    margin-left: 70px;
}

.singleTitleContainer:nth-child(4) {
    margin-left: 70px;
}

.singleTitleContainer:nth-child(5) {
    margin-left: 70px;
    margin-right: 30px;
}

.dataListTitlesContainer {
    width: 100%;
    min-width: 800px;
    height: 65px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    font-weight: bold;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
}

.tableRow {
    width: 100%;
    min-width: 800px;
    height: 45px;
    background-color: #24344d;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    cursor: pointer;
}

.tableRow:hover {
    background-color: #1e82a1;
}



.tableRowContainer {
    height: fit-content;
    min-width: fit-content;
    width: 100%;
    transition: height .5s;
}

.tableRowContainerOpened {
    transition: height .5s;
    height: 400px;
    min-width: fit-content;
    width: 100%;
}

.userInfoContainer {
    transition: height .5s;
    height: 330px;
    width: calc(100% - 20px);
    margin-left: 11px;
    background-color: #131c29;
    border-radius: 25px;
    display: flex;
    justify-content: space-around;
    color: #c1c6ca;
    margin-top: 10px;

}
.userInfoContainerClosed{
    height: 0px;
}
.userInfoContainerFlex{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    flex-direction: column;
}

.singleInfoContainer{
    width: fit-content;
    height: 60px;
    margin: 20px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #09c5ff;
    border-radius: 18px;
}

.singleInfoTitle{
    font-size: 19px;
    color: #09c5ff;
    font-weight: bold;
}

.singleInfoContent{
    font-size: 17px;
    color: #c1c6ca;
    margin-left: 20px;
    margin-top: 2px;
}
.icon{
    font-size: 25px;
    margin-top: 5px;

}

.buttonUserContainer {
    width: 130px;
    height: 45px;
    background-color: #098563;
    display: flex;
    align-items: center;
    color: #c1c6ca;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 15px;
    margin-top: 10px;
    font-size: 15px;
}

.buttonUserContainer:hover {
    background-color: #044332;
}

.buttonIcon{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}

.buttonLabel{
    margin-top: 2px;
}


.iconYes{
    font-size: 21px;
    margin-left: 15px;
    color: #01ff01;
}

.iconX{
    font-size: 23px;
    margin-left: 16px;
    color: red;
}

.image{
    width: 555px;
}

.mainContainer{
    width: 100%;
    height: 100%;
    display: flex;

}

.userData{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.userInfo{
    height: 300px;
    width: 505px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 25px rgb(0 159 255);
    position: relative;
    display: flex;
    background-position: 0px 60px;
    background-size: 515px;
    opacity: 0.9;
    transform: rotateY(0deg);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s;
}
.userInfo2{
    height: 300px;
    width: 505px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: 0px 0px 25px rgb(0 159 255);
    position: relative;
    display: flex;
    background-position: 0px 60px;
    background-size: 515px;
    opacity: 0.9;
    transform: rotateY(0deg);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s;
}
.tableContainer{
    width: 50%;
    height: 100%;
}
.cardsTableContainer{
    height: 325px;
    width: 430px;
    background-color: #131c29;
    border-radius: 8px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    color: #8f8f8f;
    overflow-y: auto;
    position: relative;
    border: 1px solid #09c5ff;
}
.iconCard{
    color: #43486d;
    font-size: 40px;
    transform: rotateZ(90deg);
    position: absolute;
    right: 35px;
    top: 130px;
}
.wave{
    position: absolute;
    width: 506px;
    transform: rotateZ(0deg);
    right: 0;
    top: -211px;
    opacity: 0.3;
}
.cardName{
    font-weight: bold;
    font-size: 30px;
    margin-left: 30px;
    color: #274775;
}

.logo{
    width: 300px;
    position: absolute;
    left: -5px;
    top: 22px;
}

.firstRawContainer{
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #8f8f8f;
    display: flex;
    align-items: center;
}
.tableHeader{
    font-size: 22px;
    font-weight: bold;
    margin-left: 25px;
}
.tableHeader:last-child{
    margin-left: 55px;
}

.tableRowCard{
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #8f8f8f;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.tableRowCard:hover{
    background-color: #343e4d;
}
.tableRowCardActive{
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #8f8f8f;
    background-color: #1a4f03;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.tableContent{
    font-size: 19px;
    margin-left: 50px;
}

.tableContent:last-child{
    margin-left: 65px;
}

.mainUserInfoContainer{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.avatarContainer{
    width: 155px;
    height: 155px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #00afff;
    position: absolute;
    top: 70px;
    left: 130px;
}
.avatar{
    width: 100%;
    position: absolute;
    top: -2px;

}
.mainUserInfo{
    width: 425px;
    height: 615px;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    border-radius: 15px;
    box-shadow: 0px 0px 15px rgb(0 159 255);
}

.logo2{
    width: 300px;
    position: absolute;
    right: -5px;
    top: 5px;
}
.waves2{
    width: 100%;
    opacity: 0.3;
    position: absolute;

}

.hexCode{
    position: absolute;
    right: 30px;
    bottom: 8px;
    font-size: 17px;
    font-weight: bold;
    color: #4b515d;
}
.contact{
    position: absolute;
    left: 0px;
    bottom: 20px;
    height: 30px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconPhone{
    color: #b39f00;
    margin-right: 10px;
    font-size: 19px;
    transform: rotateZ(45deg);
}

.addButton{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 80px;
    height: 39px;
    background-color: #09c5ff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.addButton:hover{
    background-color: #0c5975;
}
.rules{
    position: absolute;
    left: 15px;
    top: 100px;
}
.rules p{
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    color: #5d5d5d;
}

.headerBCG{
    height: 150px;
    width: 100%;
    background-color: #630146;
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.userHeader{
    margin-top: 85px;
    font-size: 24px;
    font-weight: bold;
    align-self: center;
}

.infoBox{
    justify-self: flex-start;
    display: flex;
    height: 50px;
    align-items: center;
}
.infoDescription{
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
}

.infoData{
    margin-left: 25px;
    margin-top: 22px;
    font-size: 18px;
}

.buttonsContainer{
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    font-size: 20px;
}

.iconEdit:hover{
    color: #018dff;
}

.iconDelete:hover{
    color: #c00000;
}

@media only screen and (max-width: 1400px) {
    .mainUserInfo {
        width: 300px;
        height: 455px;
        margin-top: 60px;
    }
    .avatarContainer {
        width: 110px;
        height: 110px;
        top: 35px;
        left: 95px;
    }
    .headerBCG{
        height: 95px;
    }
    .avatar {
        top: -1px;
    }
    .userHeader {
        margin-top: 55px;
        font-size: 20px;
    }
    .infoBox {
        height: 40px;
    }
    .infoDescription {
        font-size: 18px;
    }
    .infoData {
        margin-left: 16px;
        margin-top: 14px;
        font-size: 15px;
    }
    .userInfo {
        height: 230px;
        width: 390px;
        background-position: 0px 45px;
        background-size: 400px;
    }
    .iconCard {
        font-size: 28px;
        right: 27px;
        top: 95px;
    }
    .wave {
        width: 400px;
        top: -175px;
    }
    .logo {
        width: 210px;
        left: -5px;
        top: 10px;
    }
    .userInfo2 {
        height: 230px;
        width: 390px;
    }
    .logo2 {
        width: 200px;
    }
    .iconPhone {
        font-size: 14px;
    }
    .contact {
        font-size: 14px;
        width: 155px;
    }
    .rules{
        left: 13px;
        top: 80px;
    }
    .rules p {
        font-size: 11px;
        margin: 0;
        font-weight: bold;
        color: #5d5d5d;
    }
    .hexCode {
        right: 35px;
        bottom: 15px;
        font-size: 13px;
    }
    .firstRawContainer {
        height: 55px;
    }
    .tableHeader {
        font-size: 17px;
        margin-left: 30px;
    }
    .addButton {
        right: 15px;
        top: 10px;
        width: 70px;
        height: 35px;
        border-radius: 9px;
        font-size: 14px;
    }
    .tableHeader:last-child {
        margin-left: 25px;
    }
    .tableRowCardActive {
        height: 40px;
    }
    .tableRowCard {
        height: 40px;
    }
    .tableContent {
        font-size: 18px;
        margin-left: 34px;
    }
    .buttonsContainer {
        width: 50px;
        right: 20px;
        font-size: 17px;
    }
}

@media only screen and (max-width: 1200px) {
    .mainContainer {
        flex-direction: column;
        align-items: center;
    }
    .userData {
        flex-direction: column-reverse;
    }
}

@media only screen and (max-width: 1000px){
    .cardsTableContainer {
        width: 375px;
    }
}