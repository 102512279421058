:root {
  --vh: 100%;
}

.App {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
}
.nano{
    font-size: 25px;
    position: absolute;
    left: 15px;
    bottom: 0;
    color: #2bb7e3;
    font-weight: bold;

}
.blur{
    color: #fff;
    font-weight: 300;
    font-size: 15px;
}

@media only screen and (max-width: 1450px) {
    .nano{
        z-index: 13;
        font-size: 20px;
    }
    .blur{
        font-size: 13px;
    }
}

@media only screen and (max-width: 700px) {
    :root {
        --vh: 100%;
      }
      
      .App{
        height: calc(var(--vh, 1vh) * 100);
      }
      .nano{
        margin: 7px 0px;
      }
}