
.button{
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    color: #72c2ed;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 16px;
    overflow: hidden;
    transition: .2s;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button:hover{
    color: #255784;
    background: #72c2ed;
    box-shadow: 0 0 1px #72c2ed, 0 0 14px #72c2ed, 0 0 22px #72c2ed;
    transition-delay: .2s;
}

.button span {
    position: absolute;
    display: block;
}

.button span:first-child {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#72c2ed);
}
.button:hover span:first-child {
    left: 100%;
    transition: 1s;
}

.button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#72c2ed);
}
.button:hover span:nth-child(2) {
    top: 100%;
    transition: 1s;
    transition-delay: 25ms;
}
.button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#72c2ed);
}
.button:hover span:nth-child(3) {
    right: 100%;
    transition: 1s;
    transition-delay: .05s;
}
.button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(1turn,transparent,#72c2ed);
}

.button:hover span:nth-child(4) {
    bottom: 100%;
    transition: 1s;
    transition-delay: 75ms;
}




/* ******************************************************* */
/* cancel */
/* ******************************************************* */


.cancelButton{
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    color: #b30027;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 16px;
    overflow: hidden;
    transition: .2s;
    margin-top: 20px;
    margin-bottom: 30px;
    width: fit-content;
    align-self: center;
}

.cancelButton:hover{
    color: #10273b;
    background: #fd0202;
    box-shadow: 0 0 1px #fd0202, 0 0 14px #fd0202, 0 0 22px #fd0202;
    transition-delay: .2s;
}

.cancelButton span {
    position: absolute;
    display: block;
}

.cancelButton span:first-child {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#fd0202);
}
.cancelButton:hover span:first-child {
    left: 100%;
    transition: 1s;
}

.cancelButton span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#fd0202);
}
.cancelButton:hover span:nth-child(2) {
    top: 100%;
    transition: 1s;
    transition-delay: 25ms;
}
.cancelButton span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#fd0202);
}
.cancelButton:hover span:nth-child(3) {
    right: 100%;
    transition: 1s;
    transition-delay: .05s;
}
.cancelButton span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(1turn,transparent,#fd0202);
}

.cancelButton:hover span:nth-child(4) {
    bottom: 100%;
    transition: 1s;
    transition-delay: 75ms;
}