.headerContainer {
    height: 80px;
    width: 100%;
}

.header {
    color: #8f8f8f;
    font-size: 26px;
    font-weight: bold;
    margin-left: 30px;
}


@media only screen and (max-width: 700px){
    .headerContainer {
        height: 65px;
    }
    .header {
        font-size: 21px;
        margin-left: 20px;
    }
}