.firstRaw {
    display: flex;
    height: 55px;
    width: 100%;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
}

.cardsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.cardContainer {
    width: 360px;
    height: 280px;
    background-color: white;
    margin: 20px;
    cursor: pointer;
    border: 3px solid rgb(223, 223, 223);
    border-radius: 7px;
    text-decoration: none;
}
.cardContainer:hover{
    box-shadow: 0px 0px 19px #2bb7e3;
}

.cardTop {
    width: 100%;
    height: 55px;
    background-color: rgb(255, 255, 255);
    border-bottom: 3px solid rgb(241, 241, 241);
    position: relative;
    margin-bottom: 55px;
}

.iconContainer {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgb(0, 170, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -33px;
    left: 30px;
}
.iconContainerRed {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgb(170, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -33px;
    left: 30px;
}
.iconContainerBlue {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgb(3, 0, 170);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -33px;
    left: 30px;
}
.iconContainerOrange {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgb(170, 82, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -33px;
    left: 30px;
}
.iconContainerPurple {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgb(151, 2, 119);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -33px;
    left: 30px;
}
.iconContainerLightBlue {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: rgb(0, 139, 139);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -33px;
    left: 30px;
}
.cardTextContainer{
    margin-left: 30px;
}
.icon {
    font-size: 22px;
    color: white;

}
.textVoen{
    color: #8f8f8f;
    margin-bottom: -20px;
}
.textCompany{
    font-size: 24px;
    font-weight: bold;
}
.textNumber{
    color: #8f8f8f;
    font-weight: bold;
}
.textMail{
    font-size: 20px;
    color: #8f8f8f;
}