.button {
    width: 90px;
    height: 45px;
    background-color: #317498;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c1c6ca;
    cursor: pointer;
    border-radius: 15px;
    margin-left: 25px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
}

.button:hover {
    background-color: #154a67;
}

@media only screen and (max-width: 700px) {
    .button {
        width: 70px;
        height: 35px;
        border-radius: 10px;
        margin-left: 15px;
        margin-top: 20px;
        font-size: 13px;
    }
}