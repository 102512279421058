.main {
    width: 245px;
    height: calc(100% - 90px);
    box-shadow: 4px 5px 11px #3a5563;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftMenuList {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;

}

.leftMenuListItem {
    width: 100%;
    height: fit-content;
    color: #8f8f8f;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.leftMenuListItem:hover {
    color: #2bb7e3;
}

.leftMenuListItem:hover .pointer {
    background-color: #2bb7e3;
}

.leftMenuListItem:hover .rightArrow {
    color: #2bb7e3;
}

.icon {
    font-size: 25px;
    margin-top: 5px;

}

.leftItemText {
    margin-left: 5px;
    font-size: 21px;
    font-weight: bold;
}

.reset {
    text-decoration: none;
}

.pointer {
    width: 5px;
    height: 80%;
    background-color: none;
}

.iconContainer {
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightArrow {
    color: #8f8f8f;
    font-size: 23px;
    position: absolute;
    right: 20px;
    transition: all 0.2s;
}

.arrowTurned {
    color: #8f8f8f;
    font-size: 23px;
    position: absolute;
    right: 20px;
    transform: rotate(90deg);
    transition: all 0.2s;
}

.subdirectory {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.closed {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 0px;
    overflow: hidden;
}

.subList {
    width: 195px;
    height: 45px;
    color: #8f8f8f;
    margin: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.subPointer {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: none;
}

.subList:hover {
    color: #2bb7e3;
}

.subList:hover .subPointer {
    background-color: #2bb7e3;
}

.leftSubText {
    font-size: 15px;
}

.subIconContainer {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subIcon {
    font-size: 20px;
}

@media only screen and (max-width: 1450px) {
    .leftItemText {
        font-size: 17px;
        font-weight: bold;
        margin: 5px 0px;
    }

    .iconContainer {
        width: 55px;
        height: 55px;
    }
}

@media only screen and (max-height: 800px) {
    .main {
        width: 220px;
    }

    .leftItemText {
        font-size: 15px;
    }

    .iconContainer {
        height: 40px;
        width: 45px;
    }

    .icon {
        font-size: 20px;
        margin-top: 3px;
    }

    .pointer {
        width: 3px;
        height: 75%;
    }

    .leftSubText {
        font-size: 14px;
    }

    .subIconContainer {
        width: 40px;
        height: 40px;
        display: flex;
    }

    .subIcon {
        font-size: 17px;
    }

    .rightArrow,
    .arrowTurned {
        font-size: 21px;
    }
}

@media only screen and (max-width: 700px) {
    .main {
        left: -260px;
        z-index: 10;
        background-color: #131c29;
        transition: left 0.5s;
        width: 245px;
        height: calc(100% - 70px);
        box-shadow: 4px 5px 11px #3a5563;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main_open {
        left: 0px;
        z-index: 1001;
        background-color: #131c29;
        transition: left 0.5s;
        width: 245px;
        height: calc(100% - 70px);
        box-shadow: 4px 5px 11px #3a5563;
        position: fixed;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media only screen and (max-width: 500px) {
    .main {
        height: calc(100% - 60px);
    }

    .main_open {
        height: calc(100% - 60px);
    }
}