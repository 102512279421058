.selectWrapper {
    border-radius: 10px;
    display: inline-block;
    overflow: hidden;
    background: #131c29;
    border: 1px solid #313131;
    width: 70px;
    margin-bottom: 15px;
    position: absolute;
    left: 450px;
    top: 25px;
}

.select {
    width: 100%;
    height: 41px;
    border: 1px solid #131c29;
    outline: none;
    padding: 10px;
    background-color: #131c29;
    color: #8f8f8f;
    font-size: 17px;
}

@media only screen and (max-width: 1600px){
    .selectWrapper {
        left: 360px;
    }
}

@media only screen and (max-width: 1400px){
    .selectWrapper {
        left: 320px;
    }
}
@media only screen and (max-width: 1200px){
    .selectWrapper {
        left: 270px;
    }
}

@media only screen and (max-width: 1100px){
    .selectWrapper {
        left: 170px;
    }
}
@media only screen and (max-width: 900px){
    .selectWrapper {
        left: 110px;
    }
}
@media only screen and (max-width: 800px){
    .selectWrapper {
        left: 90px;
    }
}
@media only screen and (max-width: 700px){
    .selectWrapper {
        left: 90px;
        top: 15px;
    }
}

@media only screen and (max-width: 640px){
    .selectWrapper {
        left: 80px;
        top: 15px;
    }
}

@media only screen and (max-width: 500px){
    .selectWrapper {
        left: 100px;
        top: 16px;
        width: 50px;
    }
    .select {
        width: 100%;
        height: 30px;
        padding: 5px;
        font-size: 13px;
    }
}
@media only screen and (max-width: 400px){
    .selectWrapper {
        left: 75px;
    }
}