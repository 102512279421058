.fullScreen{
    width: 100vw;
    height: 100vh;
    background-color: #0c0f27d9;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 20;
    transition: all 1s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fullScreenSmall{
    width: 100%;
    height: 100%;
    /* z-index: 20; */
    transition: all 1s;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
}
.rectanglesContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}




.rectangle1{
    width: 40px;
    height: 40px;
    animation-name: move;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
}
.rectangle2{
    width: 40px;
    height: 40px;
    animation-name: move;

    animation-duration: 0.4s;
    animation-iteration-count: infinite;
}
.rectangle3{
    width: 40px;
    height: 40px;
    animation-name: jump;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
}
@keyframes move {
    0% {
        transform: translateX(0);
    }
    100%{
        transform: translateX(-40px);
    }
}
@keyframes jump {
    0% {
        transform: translateY(0) translateX(0);
    }
    33% {
        transform: translateY(-40px) translateX(0);
    }
    66%{
        transform: translateY(-40px) translateX(80px);
    }
    100%{
        transform: translateY(0) translateX(80px);
    }
}