:root {
    --vh: 100%;
  }
.modalWindow {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    left: 0;
    top: 0;
    background-color: #c6c8d39c;
    z-index: 1010;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContainer {
    height: 580px;
    background-color: #131c29;
    border-radius: 15px;
    position: relative;
}
.modalTitle {
    color: #8f8f8f;
    font-size: 26px;
    font-weight: bold;
    margin-left: 30px;
}
.icon {
    font-size: 30px;
    color: #c13434;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.modalInputsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.modalInput {
    height: 18px;
    background-color: #131c29;
    color: #4b515d;
    margin: 15px 60px;
    border-radius: 10px;
    border: 1px solid #313131;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, .07);
    font-family: inherit;
    font-size: 1em;
    line-height: 1.45;
    outline: none;
    padding: 0.6em 1.45em 0.7em;
    transition: .18s ease-out;
    width: 240px;

}

.modalInput:focus {
    color: #8f8f8f;
    border: 1px px solid #80bfdf;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, .01), 0 0 8px rgba(0, 0, 0, .2);
}

.modalInput:hover {
    box-shadow: inset 1px 0 5px #80bfdf;
}

.selectWrapper {
    border-radius: 10px;
    display: inline-block;
    overflow: hidden;
    background: #131c29;
    border: 1px solid #313131;
        width: 285px;
        margin-bottom: 15px;
}

.select {
    width: 100%;
    height: 41px;
    border: 1px solid #131c29;
    outline: none;
    padding: 10px;
    background-color: #131c29;
    color: #8f8f8f;
    font-size: 17px;
}



.button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    color: #72c2ed;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    font-size: 16px;
    overflow: hidden;
    transition: .2s;
    margin-top: 20px;
}

.button:hover {
    color: #255784;
    background: #72c2ed;
    box-shadow: 0 0 1px #72c2ed, 0 0 14px #72c2ed, 0 0 22px #72c2ed;
    transition-delay: .2s;
}

.button span {
    position: absolute;
    display: block;
}

.button span:first-child {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #72c2ed);
}

.button:hover span:first-child {
    left: 100%;
    transition: 1s;
}

.button span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #72c2ed);
}

.button:hover span:nth-child(2) {
    top: 100%;
    transition: 1s;
    transition-delay: 25ms;
}

.button span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #72c2ed);
}

.button:hover span:nth-child(3) {
    right: 100%;
    transition: 1s;
    transition-delay: .05s;
}

.button span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(1turn, transparent, #72c2ed);
}

.button:hover span:nth-child(4) {
    bottom: 100%;
    transition: 1s;
    transition-delay: 75ms;
}


@media only screen and (max-width: 700px){
    .modalContainer {
        height: 650px;
        overflow: auto;
    }
    .modalTitle{
        font-size: 22px;
        margin-left: 20px;
    }
    .icon{
        font-size: 27px;
        right: 30px;
        top: 25px;
    }
}