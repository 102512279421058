:root {
    --vh: 100%;
  }
.modalWindow {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    left: 0;
    top: 0;
    background-color: #c6c8d39c;
    z-index: 1010;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalContainer {
    height: 500px;
    width: 1040px;
    background-color: #131c29;
    border-radius: 15px;
    position: relative;
    overflow: scroll;
}
.modalTitle {
    color: #8f8f8f;
    font-size: 26px;
    font-weight: bold;
    margin-left: 30px;
    height: 70px;
    margin-bottom: 0;
    margin-top: 30px;
}
.icon {
    font-size: 30px;
    color: #c13434;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.tableContainer{
    width: 100%;
    height: 400px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.tableHeadContainer{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #8f8f8f;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid #8f8f8f;
}
.tableHeadNo{
    width: 50px;
    padding-left: 20px;
}

.tableHeadDate{
    width: 130px;
    padding-left: 20px;
}
.tableHeadTime, .tableHeadAmount, .tableHeadBalance{
    width: 80px;
    padding-left: 20px;
}
.tableHeadSource{
    width: 110px;
    padding-left: 20px;
}
.tableHeadId{
    width: 180px;
    padding-left: 20px;
}


.tableContentContainer{
    width: 100%;
    height: calc(100% - 50px);
}
.tableDataContainer{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #8f8f8f;
    font-size: 20px;
    border-bottom: 1px solid #8f8f8f;
    cursor: pointer;

}
.tableDataContainer:hover{
    background-color: #004c6f;
}
.modalHeadContainer{
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: space-between;
}
.inputsContainer{
    width: 455px;
    height: 60px;
    margin-right: 100px;
    display: flex;
    align-items: center;
}
.textInputContainer{
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
}
.smallButtonRed{
    width: fit-content;
    height: 40px;
    padding: 0 20px;
    color: white;
    display: flex;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #9d0d0d;
    margin-left: 20px;
    border-radius: 10px;
}
.smallButtonGreen{
    width: fit-content;
    height: 40px;
    padding: 0 20px;
    color: white;
    display: flex;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #0c9116;
    margin-left: 20px;
    border-radius: 10px;
}

.modalInput {
    height: 15px;
    background-color: #131c29;
    color: #4b515d;
    margin: 5px 10px;
    border-radius: 10px;
    border: 1px solid #313131;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, .07);
    font-family: inherit;
    font-size: 1em;
    line-height: 1.45;
    outline: none;
    padding: 0.6em 1.45em 0.7em;
    transition: .18s ease-out;
    width: 90px;
}

.modalInput:focus {
    color: #8f8f8f;
    border: 1px px solid #80bfdf;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, .01), 0 0 8px rgba(0, 0, 0, .2);
}

.modalInput:hover {
    box-shadow: inset 1px 0 5px #80bfdf;
}


@media only screen and (max-width: 900px){
    .tableContainer {
        width: fit-content;
    }

    .modalContainer{
        width: fit-content;
    }
}
@media only screen and (max-width: 700px){
    .modalHeadContainer{
        flex-direction: column;
        margin-bottom: 50px;
    }
    .inputsContainer{
        margin-top: 25px;
        margin-right: 0;
    }
}
@media only screen and (max-width: 450px){
    .modalHeadContainer{
        margin-bottom: 135px;
    }
    .inputsContainer{
        flex-direction: column;
        height: 150px;
        align-items: center;
    }
    .textInputContainer {
        width: 125px;
        height: 50px;
    }
    .smallButtonGreen {
        height: 40px;
        margin-left: 35px;
        margin-top: 5px;
    }
    .smallButtonRed {
        height: 40px;
        margin-left: 35px;
        margin-top: 5px;
    }
}