
.foldersContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap:wrap;

}

.cardContainer {
    width: fit-content;
    height: 70px;
    background-color: #2e353f;
    margin: 20px;
    cursor: pointer;
    border: 3px solid #2cb6e1;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding-right: 30px;
    text-decoration: none;
}
.cardContainer:hover {
    border: 3px solid #0e7797;
}
.icon{
    font-size: 35px;
    margin-left: 30px;
    color: #2cb6e1;
}

.title{
    margin-left: 19px;
    font-size: 20px;
    font-weight: bold;
    color: white;
}

@media only screen and (max-width: 700px){
    .foldersContainer {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
    }

    .cardContainer {
        width: 250px;
        height: 65px;
        margin: 10px;
        border: 2px solid #2cb6e1;
        border-radius: 12px;
        padding-right: 10px;
    }

    .title {
        margin-left: 15px;
        font-size: 18px;
    }
}