.containerSuccessHidden{
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 500px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -5;
    border-radius: 10px;
    font-size: 20px;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 0 1px #1ebc30 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #1ebc30 inset, 0 0 0 0 transparent;
    background-color: #067911;
    color: #ffffff;
    text-align: center;
    opacity: 0;
}
.containerSuccess{
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 500px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border-radius: 10px;
    font-size: 20px;
    transition: all 0.3s;
    -webkit-box-shadow: 0 0 0 1px #1ebc30 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #1ebc30 inset, 0 0 0 0 transparent;
    background-color: #067911;
    color: #ffffff;
    text-align: center;
    opacity: 1;
}
.containerFail{
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 500px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border-radius: 10px;
    font-size: 20px;
    transition: all 0.3s;
    background-color: #930f03;
    color: white;
    -webkit-box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
    text-align: center;
    opacity: 1;
}
.containerFailHidden{
    position: absolute;
    bottom: 40px;
    right: 50px;
    width: 500px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    border-radius: 10px;
    font-size: 20px;
    transition: all 0.3s;
    background-color: #930f03;
    color: white;
    -webkit-box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
    box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
    text-align: center;
    opacity: 0;
}