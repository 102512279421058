.main {
    background-color: #131c29;
    width: 100%;
    height: 90px;
    box-shadow: 0 5px 11px #3a5563;
    z-index: 1005;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 270px;
}

.hamburgerContainer {
    margin: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}

.hamburgerLine1,
.hamburgerLine2,
.hamburgerLine3 {
    width: 50%;
    height: 1px;
    background-color: #a3a3a3;
    margin: 2px auto;
    transition: 0.3s all;
}
.hamburgerLine1Active,
.hamburgerLine2Active,
.hamburgerLine3Active {
    width: 50%;
    height: 1px;
    background-color: #a3a3a3;
    margin: 2px auto;
    transition: 0.3s all;
}
.leftContainer{
    width: 30%;
    height: 100%;
    display: flex;
}

.centerContainer,
.rightContainer{
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.leftInnerContainer{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textContainer{
    display: flex;
    flex-direction: column;
    color: #8f8f8f;
    justify-content: center ;

}

.userText{
    margin: 0;
    text-align: right;
    font-weight: bold;
    font-size: 22px;
}

.userTextSecond{
    margin: 0;
    text-align: right;
    font-size: 19px;
}

.avatarContainer{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-left: 25px;
    overflow: hidden;
}
.avatar{
    width: 60px;

}

.icon{
    font-size: 27px;
    color: #8f8f8f;
    margin-left: 35px;
    cursor: pointer;
}
@media only screen and (max-width: 930px){
    
    .icon {
        font-size: 25px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .avatarContainer {
        width: 45px;
        height: 45px;
        margin-left: 0;
    }
    .avatar {
        width: 45px;
    }
    .userText{
        font-size: 21px;
    }
    .userTextSecond{
        font-size: 15px;
    }
    .textContainer{
        display: flex;
        flex-direction: column;
        color: #8f8f8f;
        justify-content: center;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 800px){
    
    .icon {
        font-size: 23px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .avatarContainer {
        width: 39px;
        height: 39px;
        margin-left: 0;
    }
    .avatar {
        width: 39px;
    }
    .userText{
        font-size: 20px;
    }
    .userTextSecond{
        font-size: 14px;
    }
    .textContainer{
        display: flex;
        flex-direction: column;
        color: #8f8f8f;
        justify-content: center;
        margin-right: 15px;
    }
}
@media only screen and (max-width: 700px) {
    .hamburgerContainer {
        margin: 10px;
        width: 40px;
        height: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        outline: none;
    }
    .hamburgerContainer:focus {
        outline: none;
    }
    .hamburgerContainer:focus-visible {
        outline: none;
    }
    .leftInnerContainer{
        width: 100px;
    }
    
    .hamburgerLine2Active{
        display: none;
    }
    .hamburgerLine1Active{
        transform: rotateZ(45deg) translateY(4px) translateX(0px);
    }
    .hamburgerLine3Active{
        transform: rotateZ(-45deg) translateY(-4px) translateX(0px);
    }
    .logo{
        width: 200px;
    }
    .icon {
        font-size: 20px;
        margin-left: 10px;
        margin-right: 15px;
    }
    .avatarContainer {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        margin-left: 0px;
    }
    .avatar {
        width: 35px;
    }
    .userText{
        font-size: 16px;
    }
    .userTextSecond{
        font-size: 12px;
    }
    .textContainer{
        display: flex;
        flex-direction: column;
        color: #8f8f8f;
        justify-content: center;
        margin-right: 15px;
    }
    .main{
        height: 70px;
    }
}

@media only screen and (max-width: 630px){
    .avatarContainer{
        display: none;
    }
    .icon{
        margin-left: 0;
    }
}
@media only screen and (max-width: 500px){
    .logo {
        width: 155px;
    }
    .main{
        height: 60px;
    }
    .userText{
        font-size: 13px;
    }
    .userTextSecond{
        font-size: 10px;
    }
    .icon {
        margin-left: 0px;
        font-size: 16px;
    }
    .textContainer {
        margin-right: 8px;
        line-height: 15px;
    }
}