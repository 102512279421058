.firstRaw{
    display: flex;
    height: 55px;
    width: 100%;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
  }

.singleTitleContainer {
    width: 130px;
    min-width: 110px;
}

.singleTitleContainer:nth-child(1) {
    margin-left: 30px;
}

.singleTitleContainer:nth-child(2) {
    margin-left: 150px;
}

.singleTitleContainer:nth-child(3) {
    margin-left: 50px;
}

.singleTitleContainer:nth-child(4) {
    margin-left: 50px;
}

.singleTitleContainer:nth-child(5) {
    margin-left: 30px;
    margin-right: 30px;
}

.dataListTitlesContainer {
    width: 100%;
    min-width: fit-content;
    height: 65px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    font-weight: bold;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
}

.tableRow {
    width: 100%;
    min-width: fit-content;
    height: 45px;
    background-color: #24344d;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    cursor: pointer;
}

.tableRow:hover {
    background-color: #983131;
}



.editButton{
    width: 70px;
    border-radius: 8px;
    height: 28px;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    margin-left: 90px;
}

