.firstRaw {
    display: flex;
    height: 55px;
    width: 100%;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
}
.cardsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.cardContainer {
    width: 260px;
    height: 70px;
    background-color: #2e353f;
    margin: 20px;
    cursor: pointer;
    border: 3px solid #4ae12c;
    border-radius: 15px;
    display: flex;
}
.cardContainer:hover {
    border: 3px solid #2c861a;
}
.icon{
    font-size: 35px;
    margin-left: 30px;
    color: #4ae12c;

    margin-top: 15px;
}

.title{
    margin-left: 19px;
    font-size: 20px;
    font-weight: bold;
    color: white;
}



.selectWrapper {
    border-radius: 10px;
    display: inline-block;
    overflow: hidden;
    background: #131c29;
    border: 1px solid #313131;
        width: 245px;
        margin-top: 15px;
        margin-left: 15px;

}

.select {
    width: 100%;
    height: 41px;
    border: 1px solid #131c29;
    outline: none;
    padding: 9px;
    background-color: #131c29;
    color: #8f8f8f;
    font-size: 18px;
}
