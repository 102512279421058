.windowsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}
.window{
    width: calc(50% - 22px);
    min-width: 300px;
    height: calc(50% - 40px);
    border: 1px solid #2bb8e4;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0 0px 10px #6aacff;
    display: flex;
}
.largeWindow{
    width: calc(100% - 20px);
    height: calc(50% - 40px);
    border: 1px solid #2bb8e4;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0 0px 10px #6aacff;
    display: flex;
}
.label{
    color: #fff;
    font-size: 23px;
    font-weight: bold;
    margin-left: 25px;
}
@media only screen and (max-width: 700px) {
    .window{
        width: calc(100% - 20px);
    }
}