.inputContainer {
    width: 305px;
    height: 55px;
    align-self: flex-end;
    margin-top: 15px;
}


.input {
    height: 18px;
    background-color: #131c29;
    color: #4b515d;
    margin: 15px 0 15px 10px;
    border-radius: 10px;
    border: 1px solid #313131;
    box-shadow: inset 1px 2px 8px rgba(0, 0, 0, .07);
    font-family: inherit;
    font-size: 1em;
    line-height: 1.45;
    outline: none;
    padding: 0.6em 1.45em 0.7em;
    transition: .18s ease-out;
    width: 240px;
}


.input:focus {
    color: #8f8f8f;
    border: 1px px solid #80bfdf;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, .01), 0 0 8px rgba(0, 0, 0, .2);
}

.input:hover {
    box-shadow: inset 1px 0 5px #80bfdf;
}

@media only screen and (max-width: 700px){
    .input {
        height: 13px;
        margin: 20px 0 20px 10px;
        border-radius: 10px;
        font-size: 13px;
        padding: 10px 7px;
        width: 155px;
    }
    
}

