
/* .contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap:wrap;
} */

.singleTitleContainer {
    width: 130px;
    min-width: 110px;
}

.singleTitleContainer:nth-child(1) {
    margin-left: 30px;
}

.singleTitleContainer:nth-child(2) {
    margin-left: 40px;
}

.singleTitleContainer:nth-child(3) {
    margin-left: 70px;
}

.singleTitleContainer:nth-child(4) {
    margin-left: 70px;
}

.singleTitleContainer:nth-child(5) {
    margin-left: 30px;
    margin-right: 30px;
}
.singleTitleContainer:nth-child(6) {
    margin-left: 50px;
}

.singleTitleContainer:nth-child(7) {
    margin-left: 100px;
    margin-right: 40px;
}

.dataListTitlesContainer {
    min-width: fit-content;
    height: 65px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    font-weight: bold;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
}

.tableRow {
    min-width: fit-content;
    height: 45px;
    background-color: #24344d;
    border-bottom: 1px solid rgba(86, 96, 105, .9);
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #c1c6ca;
    cursor: pointer;
}

.tableRow:hover {
    background-color: #7b1a1a;
}