.main {
    width: calc(100% - 290px);
    height: calc(100% - 120px);
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;

}

@media only screen and (max-width: 700px) {
    .main {
        width: calc(100% - 10px);
        height: calc(100% - 100px);
        bottom: 35px;
        right: 5px;
    }
}