.firstRaw {
    display: flex;
    height: 55px;
    width: 100%;
    min-width: 800px;
    align-items: center;
    justify-content: space-between;
}
.cardsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.cardContainer {
    width: fit-content;
    height: 70px;
    background-color: #2e353f;
    margin: 20px;
    cursor: pointer;
    border: 3px solid #4ae12c;
    border-radius: 15px;
    display: flex;
}
.cardContainer:hover {
    border: 3px solid #2c861a;
}
.cardContainerDeactive {
    width: fit-content;
    height: 70px;
    background-color: #2e353f;
    margin: 20px;
    cursor: pointer;
    border: 3px solid #eb0000;
    border-radius: 15px;
    display: flex;
}
.cardContainerDeactive:hover {
    border: 3px solid #7e0101;
}
.icon{
    font-size: 35px;
    margin-left: 30px;
    color: #4ae12c;
    margin-top: 15px;
}
.iconDeactive{
    font-size: 35px;
    margin-left: 30px;
    color: #eb0000;
    margin-top: 15px;
}
.title{
    margin-left: 19px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-right: 30px;
}