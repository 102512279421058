
.secondWindow {
    background-color: #24344d;
    width: calc(100% - 25px);
    height: calc(100% - 100px);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}
  /* Hide scrollbar  */
.secondWindow::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .secondWindow {
    -ms-overflow-style: none; /* IE and Edge*/
    scrollbar-width: none; /* Firefox */
  }


  @media only screen and (max-width: 700px){
    .secondWindow {
      width: 100%;
      border-radius: 10px;
      height: calc(100% - 68px);
  }
  }

  
